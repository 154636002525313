import * as React from 'react';
import { Headline, Paragraph, Title } from '@bojagi/pablo/Typography';
import styled from 'styled-components';
import { getSpacing } from '@bojagi/pablo/styleHelpers';
import { Container } from '../components/Container';
import { Layout } from '../components/Layout';
import { Seo } from '../components/Seo';
import { SimpleText } from '../sections/SimpleText';

const DefinitionTitle = styled(Paragraph).attrs({ as: 'dt' })`
  float: left;
`;

const Definition = styled(Paragraph).attrs({ as: 'dd' })`
  margin-left: ${getSpacing(9)};
`;

const IndexPage = () => (
  <Layout showLogo>
    <Seo title="Terms of Service" />
    <SimpleText>
      <Headline>Terms of Service</Headline>
      <Paragraph>
        These are the terms of service for using Bojagi (as state of 2020-01-19).
      </Paragraph>
      <Title>General Bitkom terms</Title>
      <Paragraph>
        We use the SaaS terms of service of Bitcom e.V. as basis of our terms of service. You can
        find the pdf files containing the terms below. Please bare in mind that only the german
        version of these terms are legally binding as we are a german company. Both the SaaS terms
        and the General terms apply.
      </Paragraph>
      <ul>
        <li>
          <a
            href="https://bojagi-terms.s3.amazonaws.com/2020-01-19-bojagi-terms-saas-en.pdf"
            rel="noopener noreferrer"
            target="_blank"
          >
            Bitkom SaaS terms (english)
          </a>
        </li>
        <li>
          <a
            href="https://bojagi-terms.s3.amazonaws.com/2020-01-19-bojagi-terms-saas-de.pdf"
            rel="noopener noreferrer"
            target="_blank"
          >
            Bitkom SaaS terms (german)
          </a>
        </li>
        <li>
          <a
            href="https://bojagi-terms.s3.amazonaws.com/2020-01-19-bojagi-terms-av-en.pdf"
            rel="noopener noreferrer"
            target="_blank"
          >
            Bitkom General terms (english)
          </a>
        </li>
        <li>
          <a
            href="https://bojagi-terms.s3.amazonaws.com/2020-01-19-bojagi-terms-av-de.pdf"
            rel="noopener noreferrer"
            target="_blank"
          >
            Bitkom General terms (german)
          </a>
        </li>
      </ul>
      <Title>Additional terms</Title>
      <Paragraph>
        Additionally we add these terms to the above terms. They either extend or overwrite the
        above terms:
      </Paragraph>
      <h3>1. Availability</h3>
      <dl>
        <DefinitionTitle>1.1</DefinitionTitle>
        <Definition>
          Currently we give no warranty on availability of the service, as the service is in early
          stages.
        </Definition>
      </dl>
      <h3>2. Customer&apos;s obligations</h3>
      <dl>
        <DefinitionTitle>2.1</DefinitionTitle>
        <Definition>
          As stated in the SaaS terms §5.3 the customer must secure their data. When needed we will
          provide the possible API access to do that. Please contact us in that case.
        </Definition>
      </dl>
      <h3>3. Malfunction management</h3>
      <dl>
        <DefinitionTitle>3.1</DefinitionTitle>
        <Definition>
          In case of a malfunction of the software, the customer needs to inform us about these as
          soon as possible. This can be done either via email to{' '}
          <a href="mailto: support@bojagi.io">support@bojagi.io</a> or via the{' '}
          <a
            href="https://github.com/Bojagi/issue-tracker/issues"
            rel="noopener noreferrer"
            target="_blank"
          >
            GitHub issue tracker
          </a>
          . Please look into our{' '}
          <a href="https://bojagi.statuspal.io/" rel="noopener noreferrer" target="_blank">
            status page
          </a>{' '}
          and the issue tracker beforehand to see if this is already a known issue.
        </Definition>
      </dl>
      <h3>4. Privacy</h3>
      <dl>
        <DefinitionTitle>4.1</DefinitionTitle>
        <Definition>
          Part of our terms of service are is our{' '}
          <a href="/privacy" target="_blank">
            privacy policy
          </a>
          .
        </Definition>
        <DefinitionTitle>4.2</DefinitionTitle>
        <Definition>
          The scope of our software doesn&apos;t include processing of data of your customer. If you
          think this might be the case for (e.g. you upload customer data to us), please contact us
          via <a href="mailto: support@bojagi.io">support@bojagi.io</a>.
        </Definition>
      </dl>
      <h3>5. Contract duration and termination</h3>
      <dl>
        <DefinitionTitle>5.1</DefinitionTitle>
        <Definition>
          The contract may be terminated with a notice period of one month. The minimum term is one
          month. If the contract is not terminated, the contract shall be extended by one more
          month, unless it was terminated ordinarily with a notice period of 3 months before expiry
          of the respective extension period. This overwrites §9.2 of the SaaS terms.
        </Definition>
        <DefinitionTitle>5.2</DefinitionTitle>
        <Definition>
          We don&apos;t delete data after contract termination. If you wish to do so, please contact
          us via email to <a href="mailto: support@bojagi.io">support@bojagi.io</a>.
        </Definition>
      </dl>
    </SimpleText>
    <Container />
  </Layout>
);

export default IndexPage;
